export const LESSON_CHECKMARK_ALLOWED = 'LESSON_CHECKMARK_ALLOWED';
export const ADD_LESSON_ALLOWED = 'ADD_LESSON_ALLOWED';
export const ADD_EDIT_ACQUISITION_TARGET_ALLOWED = 'ADD_EDIT_ACQUISITION_TARGET_ALLOWED';
export const CAPTURE_MOPS_ALLOWED = 'CAPTURE_MOPS_ALLOWED';
export const CAPTURE_STAFF_TRAINING_ALLOWED = 'CAPTURE_STAFF_TRAINING_ALLOWED';
export const CAPTURE_TIPS_ALLOWED = 'CAPTURE_TIPS_ALLOWED';

export const screenList = [
    {
        name: 'Staff List',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Add/Edit Staff',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin']
        }
    },
    {
        name: 'Staff Profile',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Client List',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Add/Edit Client',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin']
        }
    },
    {
        name: 'Client Profile',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Add Appointment',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Appointment Details',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Add New Task',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Report Scoring',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'Add Staff Training',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Caregiver Training',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'Parent Training Subsequent Screens',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'Add Team Meeting',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },

    {
        name: 'Add Direct Service Task',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Add Other Task',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Add Todo Task',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', , 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', , 'Behavioral Therapist']
        }
    },
    {
        name: 'Add TIPS/MOPS',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'MOPS Subsequent Screens',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'TIPS Subsequenct Screens',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'View Behavioural Support Plan',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Add New Behavior Support Plan',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'View/Edit Behavior Support Plan',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Edit Behavior Support Plan',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Add/Edit Acquire/Mastered Targets',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: LESSON_CHECKMARK_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'View Acquire/Mastered Targets',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Add/Edit Behavior Reduction',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'View Behavior Reduction',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'View Lesson Progress Sheet',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Edit Lesson Progress Sheet',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Update Client Schedule',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'Performance Indicator',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'MTBCBASupervisor',
        access: {
            RW: ['Super Admin'],
            R: ['Super Admin', 'BCBA Supervisor']
        }
    },
    {
        name: 'MTProgramManager',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'MTSeniorTherapist',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', , 'Senior Therapist']
        }
    },
    {
        name: 'Authorization Timeline For BCBA Supervisor',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor'],
            R: ['Super Admin', 'BCBA Supervisor']
        }
    },
    {
        name: 'Authorization Timeline For Program Manager',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: 'Analytics',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist', 'Behavioral Therapist']
        }
    },
    {
        name: 'Authorization Timeline For Senior Therapist',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: 'Calendar Staff List',
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: ADD_LESSON_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager']
        }
    },
    {
        name: ADD_EDIT_ACQUISITION_TARGET_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: CAPTURE_MOPS_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: CAPTURE_TIPS_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    },
    {
        name: CAPTURE_STAFF_TRAINING_ALLOWED,
        access: {
            RW: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist'],
            R: ['Super Admin', 'BCBA Supervisor', 'Program Manager', 'Senior Therapist']
        }
    }
];
